<template>
  <div>
    <a-layout>
      <div class="content-header">
        <a-affix :offset-top="0" style="width: 100%;">
          <a-row type="flex" justify="space-between">
            <a-col span="12">
              <common-page-btn @add="$refs.manageForm._show()"></common-page-btn>
            </a-col>
            <a-col span="12">
              <a-row type="flex" align="middle" justify="end" :gutter="3">
                <a-col span="12">
                  <a-input v-model="param.keyword" allowClear search @pressEnter="getAssignList" @on-clear="getAssignList"
                    placeholder="关键词查询"></a-input>
                </a-col>
                <a-col span="6" v-if="getOrganId() == 0">
                  <a-select v-model="param.organId" style="width:100%" @change="e => {
                                      this.param.organId = e;
                                      doRefresh();
                                    }
                                      " placeholder="根据单位筛选">
                    <a-select-option value="">所有单位</a-select-option>
                    <a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
                                          }}</a-select-option>
                  </a-select>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-affix>
      </div>
    </a-layout>

    <a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25"
      :style="{ background: '#FFF' }" size="small" row-key="staffId" border ref="list" :loading="loading"
      :data-source="data">
      <template slot-scope="row" slot="organId">
        <div>{{ organList.find(item => item.organId == row.organId).name }}</div>
      </template>
      <!--操作-->
      <template slot-scope="row" slot="action">
        <a-button-group shape="circle" size="small">
          <a-tooltip title="删除"><a-button type="link" v-has="'car:assign:del'" icon="delete"
              @click="del(row.staffId)"></a-button></a-tooltip>
        </a-button-group>
      </template>
    </a-table>

    <manage-form ref="manageForm" @success="doRefresh"></manage-form>
  </div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import manageForm from './components/manageForm.vue';
import utils from '@/common/utils';
export default {
  components: { manageForm },
  data() {
    return {
      data: [],
      loading: false,
      organList: apiUtil.getOrganList(),
      param: {
        keyword: '',
        organId: apiUtil.getOrganId('')
      },
      columns: [
        { title: '管理人姓名', dataIndex: 'name', width: 80, ellipsis: true, align: 'center' },
        { title: '所属单位', scopedSlots: { customRender: 'organId' }, align: 'center', width: 80, ellipsis: true },
        { title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
      ]
    };
  },
  mounted() {
    this.getAssignList();
  },
  methods: {
    ...apiUtil,
    // 获取调度人员列表
    getAssignList() {
      this.loading = true;
      this.http
        .get('/platform/reward/store/managers/list', this.param)
        .then(ret => {
          this.data = ret.data;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
        });
    },
    // 删除
    del(id) {
      this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
        utils.showSpin();
        this.http.delete('/platform/reward/store/managers/del/' + id).then(ret => {
          if (ret.code == 200) {
            this.utils.success('操作成功！').then(() => {
              this.getAssignList();
              // console.log(1111);
            });
          }
        });
      });
    },
    // 筛选条件变化重新获取数据
    doRefresh() {
      this.param.current = 1;
      this.getAssignList();
    },

    filterOrgan(e) {
      console.log(e);
      this.getAssignList();
    }
  }
};
</script>

<style></style>
